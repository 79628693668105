import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { MerchantStore } from '../../../data/models/merchantStore.model';
import 'leaflet-defaulticon-compatibility';


// Define a default icon for leaflet markers (if not set, markers will not be visible)
L.Marker.prototype.options.icon = L.icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
  shadowSize: [41, 41]
});

const StoreMap: React.FC<{ stores: MerchantStore[] }> = ({ stores }) => {
  const defaultPosition: [number, number] = [14.5995, 120.9842]; // Default to Manila, Philippines coordinates

  return (
    <MapContainer
      center={defaultPosition}
      zoom={13}
      scrollWheelZoom={true}
      style={{ height: '500px', width: '100%' }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {stores.map((store) => (
        store?.Location?.Latitude != null ?
        <Marker key={store.MerchantStoreId} position={[store.Location.Latitude, store.Location.Longitude]}>
          <Popup>
            <strong>{store.StoreName}</strong>
            <br />
            {store.BusinessAddress}
            <br />
            Owner: {store.OwnerUsers[0]?.FullName || 'N/A'}
          </Popup>
        </Marker> : <></>
      ))}
    </MapContainer>
  );
};

export default StoreMap;
