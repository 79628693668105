import React, { useState } from 'react';
import { Table, DatePicker, Select, Row, Col, Card } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { MerchantStore } from '../../../data/models/merchantStore.model';
import StoreMap from './storeMap';

const { RangePicker } = DatePicker;
const { Option } = Select;

const MerchantStoreTable: React.FC<{ FSAReportData: MerchantStore[] }> = ({ FSAReportData }) => {
  const [filteredData, setFilteredData] = useState(FSAReportData);

  const [selectedReferralId, setSelectedReferralId] = useState<string | null>(null);
  const [selectedDates, setSelectedDates] = useState<RangeValue<Moment>>(null);

  // Unique Ambassador Referral Ids for the filter dropdown
  const uniqueReferralIds = Array.from(new Set(FSAReportData.map((store) => store.AmbassadorReferralId)));

  // Handle date filter change
  const handleDateFilter = (dates: RangeValue<Moment>) => {
    setSelectedDates(dates);
    filterData(selectedReferralId, dates);
  };

  const handleReferralIdFilter = (value: string) => {
    setSelectedReferralId(value);
    filterData(value, selectedDates);
  };

  const filterData = (referralId: string | null, dates: RangeValue<Moment>) => {
    let data = [...FSAReportData];

    // Filter by referralId
    if (referralId) {
      data = data.filter((store) => store.AmbassadorReferralId === referralId);
    }

    // Filter by date range, ensuring both dates are not null
    if (dates && dates[0] && dates[1]) {
      const [start, end] = dates;
      data = data.filter((store) => moment(store.CreationTimestamp).isBetween(start, end, undefined, '[]'));
    }

    setFilteredData(data);
  };

  const columns: ColumnsType<MerchantStore> = [
    {
      title: 'Store Name',
      dataIndex: 'StoreName',
      key: 'StoreName',
    },
    {
      title: 'Merchant Name',
      key: 'OwnerUsers',
      render: (_, record) => ((record?.OwnerUsers?.length ?? 0) > 0 ? record.OwnerUsers[0].FullName : 'N/A'),
    },
    {
      title: 'Merchant Phone',
      key: 'OwnerUsers',
      render: (_, record) => ((record?.OwnerUsers?.length ?? 0) > 0 ? record.OwnerUsers[0].PhoneNumber : 'N/A'),
    },

    {
      title: 'FSA Code',
      dataIndex: 'AmbassadorReferralId',
      key: 'AmbassadorReferralId',
      filters: uniqueReferralIds.map((id) => ({ text: id, value: id })),
      onFilter: (value, record) => record.AmbassadorReferralId === value,
      filterDropdown: () => (
        <Select
          onChange={handleReferralIdFilter}
          placeholder="Select Ambassador Referral Id"
          allowClear
          style={{ width: 200 }}
        >
          {uniqueReferralIds.map((id) => (
            <Option key={id} value={id}>
              {id}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Signup DateTime',
      dataIndex: 'CreationTimestamp',
      key: 'CreationTimestamp',
      render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
      sorter: (a, b) => moment(a.CreationTimestamp).unix() - moment(b.CreationTimestamp).unix(),
      defaultSortOrder: 'descend',
      filterDropdown: () => <RangePicker onChange={handleDateFilter} />,
    },
  ];

  // Utility functions to filter data by time ranges
  const filterByDateRange = (start: Moment, end: Moment) => {
    return FSAReportData.filter((store) => moment(store.CreationTimestamp).isBetween(start, end, undefined, '[]'));
  };

  // Calculate signups for different time ranges
  const today = moment().startOf('day');
  const startOfWeek = moment().startOf('week');
  const startOfMonth = moment().startOf('month');
  const startOfQuarter = moment().startOf('quarter');

  const todaySignups = filterByDateRange(today, moment());
  const weekSignups = filterByDateRange(startOfWeek, moment());
  const monthSignups = filterByDateRange(startOfMonth, moment());
  const quarterSignups = filterByDateRange(startOfQuarter, moment());

  return (
    <div>
      <div>
        {/* Cards for signups */}
        <Row gutter={16}>
          <Col span={6}>
            <Card title="Signups Today" bordered={false}>
              {todaySignups.length}
            </Card>
          </Col>
          <Col span={6}>
            <Card title="Signups This Week" bordered={false}>
              {weekSignups.length}
            </Card>
          </Col>
          <Col span={6}>
            <Card title="Signups This Month" bordered={false}>
              {monthSignups.length}
            </Card>
          </Col>
          <Col span={6}>
            <Card title="Signups This Quarter" bordered={false}>
              {quarterSignups.length}
            </Card>
          </Col>
        </Row>
      </div>
      <div>
        <h1>Merchant Stores Map</h1>
        <StoreMap stores={filteredData} />
      </div>
      <div>
        <h1>Merchant Stores</h1>
        <Table columns={columns} dataSource={filteredData} rowKey="MerchantStoreId" />
      </div>
    </div>
  );
};

export default MerchantStoreTable;
