// src/components/ReportView.js
import React, { useEffect, useState } from 'react';
import { DatePicker, Button, Table, Select } from 'antd';
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../data/storeConfig';
import * as dashboardActions from '../../data/reducers/dashboard.reducer';
import { RootState } from '../../data/reducers';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { useNavigate } from 'react-router';
import MerchantStoreTable from './components/merchantStoresTable';
import StoreMap from './components/storeMap';

const { RangePicker } = DatePicker;

const SignupsReportView = () => {
  const navigate = useNavigate();
  const [dates, setDates] = useState<Moment[]>([]);
  const [data, setData] = useState<any>([]);
  const dispatch = useDispatch<AppDispatch>();
  const { FSAReportData, FSAReportError, FSAReportLoading } = useSelector((state: RootState) => state.dashboard);

  const fetchData = () => {
    dispatch(dashboardActions.fetchFSAReport());
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <h1>YuOrder Signups Report</h1>
      
      {/* <RangePicker onChange={handleDateChange} style={{ marginLeft: 8 }} /> */}
      {/* <Button onClick={exportToExcel} disabled={!YuOrderFSASalesReport.length} style={{ marginLeft: 8 }}>
        Export to Excel
      </Button> */}
      {/* <Button onClick={() => navigate('/reports/fsa-yuOrder-search')} style={{ marginLeft: 8 }}>
        Search Order
      </Button> */}
      {/* <Table columns={columns} dataSource={YuOrderFSASalesReport} style={{ marginTop: 16 }} scroll={{ x: 1200 }} /> */}
      <MerchantStoreTable FSAReportData={FSAReportData} />
    </div>
  );
};

export default SignupsReportView;
